import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import fetch from 'isomorphic-unfetch';
import { GRAPHQL_URL } from '../Config';
import { firebaseAuth } from './firebaseHelper';
import { onError } from '@apollo/client/link/error';

let apolloClient;

// Polyfill fetch() on the server (used by apollo-client)
if (!process.browser) {
  global.fetch = fetch;
}

function createApolloClient(initialState = null) {
  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  const httpLink = new HttpLink({
    uri: GRAPHQL_URL,
    credentials: 'same-origin', // to set cookie across different port 'include'
  });

  const cache = new InMemoryCache({
    dataIdFromObject: object => object.id || null,
    addTypename: false,
  }).restore(initialState);

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors)
        graphQLErrors.map(async ({ message, locations, path }) => {
          if (message === 'auth/id-token-expired') {
            // const oldHeaders = operation.getContext().headers;
            // operation.setContext({
            //   headers: {
            //     ...oldHeaders,
            //     authorization: await AuthHelper.refreshToken(),
            //   },
            // });
            // return forward(operation);
          } else {
            console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`);
          }
        });
      if (networkError) {
        console.error(`[Network error]: ${networkError}`);
      }
    }
  );

  const authLink = setContext(async (_, { headers }) => {
    const u = await firebaseAuth().currentUser;
    if (u) {
      const token = await u.getIdToken();
      return {
        headers: {
          ...headers,
          authorization: token ? token : '',
        },
      };
    } else {
      return {
        headers: {
          ...headers,
        },
      };
    }
  });

  return new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: typeof window === 'undefined', // Disables forceFetch on the server (so queries are only run once)
    link: from([errorLink, authLink, httpLink]),
    cache,
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}

export function useApollo(initialState) {
  return initializeApollo(initialState);
}
