import {getDataFromTree} from '@apollo/client/react/ssr';
import React, {Fragment, useEffect} from 'react';
import Head from 'next/head';
import {appWithTranslation} from 'next-i18next';
import {GlobalStyles} from '@core/ui/static/global.style';
import {ThemeProvider} from 'styled-components';
import {useApollo} from '../helpers/apolloClient';
import {analytics} from '../helpers/firebaseHelper';
import {theme} from '../theme';
import NProgress from 'nprogress';
import {useRouter} from 'next/router';
import {ToastProvider} from 'react-toast-notifications';
import {UserProvider} from '../contexts/UserContext';
import AuthProvider from '../contexts/AuthProvider';
import {getCookie, getFirebaseCookie} from '../helpers/session';
import {ApolloProvider} from '@apollo/client';
import {init} from '../utils/sentry';

import FaviconApple from '@core/ui/static/images/favicon/apple-touch-icon.png';
import Favicon32 from '@core/ui/static/images/favicon/favicon-32x32.png';
import Favicon16 from '@core/ui/static/images/favicon/favicon-16x16.png';
import FaviconSafari from '@core/ui/static/images/favicon/safari-pinned-tab.svg';

import 'regenerator-runtime/runtime';

// import css
import '@redq/reuse-modal/lib/index.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-image-lightbox/style.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'nprogress/nprogress.css';
import 'rc-drawer/assets/index.css';
import 'rc-slider/assets/index.css';
import 'react-phone-number-input/style.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import '../styles/index.css';
import '../theme/override.css';

import '../styles/working_hours.scss';

import moment from 'moment';

// import moment locales
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import Script from "next/experimental-script";

/**
 * Bishlama
 * Lundi		Mandei
 * Mardi		Tusdei
 * Mercredi		Wednisdei
 * Jeudi		Thesdei
 * Vendredi		Fraidei
 * Samedi		Satedei
 * Dimanche		Sandei
 */
moment.updateLocale('bi', {
  months: 'Januari_Februari_Maj_Eprel_Mei_Jun_Julae_Okest_Septemba_Octoba_Novemba_Dicemba'.split(
    '_'
  ),
  weekdays: 'Sandei_Mandei_Tusdei_Wednisdei_Thesdei_Fraidei_Satedei'.split('_'),
  weekdaysShort: 'San_Man_Tus_Wed_The_Fra_Sat'.split('_'),
});

NProgress.configure({
  showSpinner: false,
});

// Sentry
init();

const MyApp = props => {
  const {
    apolloState: {data},
    serverApolloClient,
    Component,
    pageProps,
  } = props;

  const {error} = pageProps;

  const router = useRouter();

  // set moment locale
  moment.locale(router.locale);

  const {events} = router;

  // inject apollo cache from server
  const frontApolloClient = serverApolloClient || useApollo(data);

  useEffect(() => {
    const onRouteChangeStart = () => {
      NProgress.start();
    };

    const onRouteChangeComplete = url => {
      if (typeof window !== 'undefined' && analytics) {
        analytics.logEvent('page_view', {
          page_location: process.env.SITE_URL + url,
          page_path: url,
          page_title: document.title,
        });
      }
      NProgress.done();
    };

    const onRouteChangeError = () => {
      NProgress.done();
    };

    events.on('routeChangeError', onRouteChangeError);
    events.on('routeChangeComplete', onRouteChangeComplete);
    events.on('routeChangeStart', onRouteChangeStart);

    // unsubscribe on unmount
    return () => {
      events.off('routeChangeError', onRouteChangeError);
      events.off('routeChangeComplete', onRouteChangeComplete);
      events.off('routeChangeStart', onRouteChangeStart);
    };
  }, []);

  return (
    <Fragment>
      <Head>
        <title/>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
        />
        <meta name="theme-color" content="#00BCD4"/>
        {/* If We Didb't Load It Here It Causes Font Flicking */}
        <link rel="apple-touch-icon" sizes="180x180" href={FaviconApple}/>
        <link rel="icon" type="image/png" sizes="32x32" href={Favicon32}/>
        <link rel="icon" type="image/png" sizes="16x16" href={Favicon16}/>
        <link rel="mask-icon" href={FaviconSafari} color="#5bbad5"/>
        <meta name="msapplication-TileColor" content="#da532c"/>
        <meta name="background-color" content="#ffffff"/>
        <link rel="preconnect" href={process.env.FUNCTION_URL}/>
        <link rel="manifest" href="/manifest.json"/>
      </Head>
      <GlobalStyles/>
      <ApolloProvider client={frontApolloClient}>
        <ThemeProvider theme={theme}>
          <ToastProvider>
            <AuthProvider>
              <UserProvider>
                <Component {...pageProps} err={error}/>
              </UserProvider>
            </AuthProvider>
          </ToastProvider>
        </ThemeProvider>
      </ApolloProvider>
      <link
        href="https://unpkg.com/ionicons@4.5.0/dist/css/ionicons.min.css"
        media="screen"
        rel="stylesheet"
      />
      <Script async src="https://www.googletagmanager.com/gtag/js"/>
      <Script src="https://cdn.jsdelivr.net/gh/AmauriC/tarteaucitron.js@1.9.1/tarteaucitron.min.js"/>
      <script
        defer
        dangerouslySetInnerHTML={{
          __html: `if( typeof tarteaucitron !== 'undefined' ) {tarteaucitron.init({"privacyUrl": "/mentions-legales.html","hashtag": "#cookies", "cookieName": "tarteaucitron","orientation": "bottom","showAlertSmall": false, "cookieslist": false,"adblocker": false,"AcceptAllCta" : true,"highPrivacy": false,"showIcon":false,"handleBrowserDNTRequest": false,"removeCredit": false,"moreInfoLink": true, "useExternalCss": false,});tarteaucitron.user.gtagUa = '${process.env.MEASUREMENT_ID}';(tarteaucitron.job = tarteaucitron.job || []).push('gtag')}`,
        }}
      />
      <Script src="https://diffusion.skazy.nc/www/delivery/asyncjs.php"/>
    </Fragment>
  );
};

MyApp.getInitialProps = async appContext => {
  const {Component, ctx} = appContext;
  const {
    AppTree,
    req: {
      headers: {referer = null},
    },
  } = ctx;

  let appProps = {};
  if (Component.getInitialProps) {
    appProps = await Component.getInitialProps(ctx);
  }

  const token = getCookie('id_token', ctx);
  const user = getFirebaseCookie('user', ctx);

  const isLoggedIn = !!token;

  const {pathname} = ctx;

  let pageProps = {
    isLoggedIn,
    pathname,
  };

  if (user) {
    const {error = false, mail = false, userId = false} = user;

    pageProps = {
      ...pageProps,
      error,
      mail,
      userId,
    };
  }

  const apolloState = {data: {}};
  const client = useApollo();

  //// hack to allow SSR only on the first call
  const url = process.env.SITE_URL;
  let firstCall = !(referer && referer.startsWith(url));

  const currentEnvMode = process.env.NODE_ENV;
  if (currentEnvMode === 'production' && referer) {
    firstCall = !(
      referer.startsWith(url) || referer.startsWith('http://localhost:5000')
    );
  } else if (currentEnvMode === 'development' && referer) {
    firstCall = !referer.startsWith('http://localhost:3000');
  }

  if (typeof window === 'undefined' && firstCall === true) {
    if (ctx.res && (ctx.res.headersSent || ctx.res.finished)) {
      return pageProps;
    }

    // execute all useQuery
    try {
      const props = {...pageProps};
      const appTreeProps =
        'Component' in ctx
          ? props
          : {serverApolloClient: client, apolloState, pageProps: props};
      await getDataFromTree(<AppTree {...appTreeProps} />);
    } catch (error) {
      console.error('GraphQL error occurred [getDataFromTree]', error);
    }

    Head.rewind();
  }

  // retrieve apollo cache
  apolloState.data = client.cache.extract();

  return {
    ...appProps,
    pageProps,
    apolloState,
    token,
  };
};

export default appWithTranslation(MyApp);
