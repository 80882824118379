import { createGlobalStyle } from 'styled-components';
import latoLatinExt400 from './fonts/Lato/S6uyw4BMUTPHjxAwXjeu.woff2';
import latoLatin400 from './fonts/Lato/S6uyw4BMUTPHjx4wXg.woff2';
import latoLatinExt700 from './fonts/Lato/S6u9w4BMUTPHh6UVSwaPGR_p.woff2';
import latoLatin700 from './fonts/Lato/S6u9w4BMUTPHh6UVSwiPGQ.woff2';
import poppinsDevanagari400 from './fonts/Poppins/pxiEyp8kv8JHgFVrJJbecmNE.woff2';
import poppinsLatinExt400 from './fonts/Poppins/pxiEyp8kv8JHgFVrJJnecmNE.woff2';
import poppinsLatin400 from './fonts/Poppins/pxiEyp8kv8JHgFVrJJfecg.woff2';
import poppinsLatinExt500 from './fonts/Poppins/poppins-v9-latin-ext-500.woff2';
import poppinsDevanagari600 from './fonts/Poppins/pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2';
import poppinsLatinExt600 from './fonts/Poppins/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2';
import poppinsLatin600 from './fonts/Poppins/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2';
import poppinsDevanagari700 from './fonts/Poppins/pxiByp8kv8JHgFVrLCz7Z11lFc-K.woff2';
import poppinsLatinExt700 from './fonts/Poppins/pxiByp8kv8JHgFVrLCz7Z1JlFc-K.woff2';
import poppinsLatin700 from './fonts/Poppins/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2';

export const GlobalStyles = createGlobalStyle`
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Lato Regular'), local('Lato-Regular'), url(${latoLatinExt400}) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Lato Regular'), local('Lato-Regular'), url(${latoLatin400}) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Lato Bold'), local('Lato-Bold'), url(${latoLatinExt700}) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Lato Bold'), local('Lato-Bold'), url(${latoLatin700}) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(${poppinsDevanagari400}) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(${poppinsLatinExt400}) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(${poppinsLatin400}) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(${poppinsLatinExt500}) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* poppins-500 - latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Poppins Medium'), local('Poppins-Medium'), url(${poppinsLatinExt500}) format('woff2');
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(${poppinsDevanagari600}) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(${poppinsLatinExt600}) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(${poppinsLatin600}) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Poppins Bold'), local('Poppins-Bold'), url(${poppinsDevanagari700}) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Poppins Bold'), local('Poppins-Bold'), url(${poppinsLatinExt700}) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Poppins Bold'), local('Poppins-Bold'), url(${poppinsLatin700}) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

:not(output):-moz-ui-invalid {
    box-shadow: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: #00bcd4;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    transition: background-color 5000s ease-in-out 0s;
}
input {
    filter: none;
}

::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #00bcd4;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #01b0c7;
}
::-webkit-scrollbar-thumb:active {
  background: #00a4ba;
}
::-webkit-scrollbar-track {
  background: #e9e9e9;
  border: 0px none #ffffff;
  border-radius: 46px;
}
::-webkit-scrollbar-track:hover {
  background: #eaeaea;
}
::-webkit-scrollbar-track:active {
  background: #e7e7e7;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
html {
  overflow-x: hidden !important;
  max-width: 100% !important;
}
body {
  padding-top: 76px;
  font-family: 'Lato', sans-serif;
  margin: 0;
  background-color: #f9f9f9;
  overflow-x: hidden !important;
  overflow-anchor: none;
  max-width: 100% !important;
  @media (max-width: 768px) {
    padding-top: 56px;
  }
}

textarea:focus, input:focus{
    outline: none;
}

h1,
h2,
h3,
h4 {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  text-decoration: none;
  color: #00BCD4;
  transition-duration: 0.3s;
  &:hover {
    color: #002F3F
  }
}

#nprogress .bar {
  background: #00bcd4;
}

.form-profil-checkbox {
    margin-bottom: 20px;
}
.form-profil-checkbox label span {
    font-size: 13px !important;
}

.slider-wrapper {
    .slide {
        img {
            width: 101% !important;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .hXPUlG {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
}

.carousel .control-dots {
    padding: 0;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
    opacity: .8 !important;
    &:hover {
        opacity: 1 !important;
    }
}

textarea:disabled, input:not([type]):disabled, input[type="color" i]:disabled, input[type="date" i]:disabled, input[type="datetime" i]:disabled, input[type="datetime-local" i]:disabled, input[type="email" i]:disabled, input[type="month" i]:disabled, input[type="password" i]:disabled, input[type="number" i]:disabled, input[type="search" i]:disabled, input[type="tel" i]:disabled, input[type="text" i]:disabled, input[type="time" i]:disabled, input[type="url" i]:disabled, input[type="week" i]:disabled {
    background-color: transparent;
    cursor: not-allowed;
}
`;
